@import "~Stylesheets/base";

.DemoApp {
  &__page {
    min-height: 85vh;
  }

  &__DemoTopBar {
    padding: 16px 0;
    color: white;
    background-color: @gray800;
    position: sticky;
    border-bottom: 5px solid red;
  }

  &__DemoTopBarContainer {
    max-width: 1384px;
    width: 100%;
    padding: 0 30px;
    margin: 0 auto;
  }

  &__DemoTopBarTitle {
    font-size: 20px;
    font-weight: 600;
  }

  &__container {
    width: 70%;
    padding-left: 15%;
  }

  &__Container--BenAdmin {
    max-width: 1384px;
    width: 100%;
    padding: 0 30px;
    margin: 0 auto;
  }

  &__ExperienceHeader {
    font-weight: 800;
    padding: 8px 10px;
  }

  &__ExperienceButtonGroup {
    border: 1px solid black;
    padding: 16px;
    border-radius: 6px;
  }

  &__EmployerLandingBodyText {
    height: 120px;
  }

  &__title {
    font-weight: 800 !important;
  }

  &__benefitIcon {
    color: @rose900;
  }

  &__blockQuote {
    blockquote {
      padding-left: 20px;
      margin: 20px 0;
      border-left: 5px solid @rose900 !important;
      border-radius: 2px;
    }
  }

  &__Card {
    border-radius: 6px;
  }

  &__cardSection {
    border-right: 1px solid @gray200;
  }

  &__cardButton {
    height: 32px;
    border-radius: 3px !important; // one-off
  }

  &__smallGroupButton {
    background-color: @rose900 !important; // we don't have a color variable for this button yet
  }

  &__BORButton {
    border: 1px solid @rose900 !important;
  }

  &__logoSection{
    display: flex;
    justify-content: center;
  }

  &__logoContainer {
    display:flex;
    height: 24px;

    img {
      margin-right: 24px;
    }
  }

  &__Flow {
    padding-bottom: 16px;
  }

  &__Flow:last-child {
    padding-bottom: 0;
  }

  &__FlowTitle {
    font-weight: 600;
    margin-bottom: 16px;
  }

  &__FlowDescription {
    margin-bottom: 16px;
    font-size: 12px;
  }

  &__FlowLaunchButtonWrapper {
    width: 220px;
    margin-bottom: 16px;
  }

  &__referredByLogos {
    display: flex;
    height: 24px;
    justify-content: center;
    vertical-align: middle;

    #simplyInsuredLogo {
      height: 30px;
    }
  }

  &__referredByStatement {
    font-style: italic;
    align-self: center;
    margin-bottom: -4px; // to align with the logos
  }

  &__VideoContainer {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  }

  &__Video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  &__CopyColumn {
    display: flex;
    flex-direction: column;
  }
}
